var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("vue-headful", { attrs: { title: _vm.appName + " - Login" } }),
      _c("gov-back-link", { attrs: { to: { name: "dashboard" } } }, [
        _vm._v("Back to dashboard")
      ]),
      _c(
        "gov-main-wrapper",
        [
          _c(
            "gov-grid-row",
            [
              _c(
                "gov-grid-column",
                { attrs: { width: "two-thirds" } },
                [
                  _c("gov-heading", { attrs: { size: "xl" } }, [
                    _vm._v("Login")
                  ]),
                  !_vm.validateRequest
                    ? [
                        _c("gov-body", { attrs: { size: "l" } }, [
                          _vm._v(
                            "\n            Click below to login to the " +
                              _vm._s(_vm.appName) +
                              " admin portal:\n          "
                          )
                        ]),
                        _c("gov-button", { attrs: { href: _vm.loginUrl } }, [
                          _vm._v("Login")
                        ]),
                        _c("gov-body", { attrs: { size: "s" } }, [
                          _vm._v(
                            "\n            For security reasons, you will be automatically logged out after\n            " +
                              _vm._s(_vm.sessionMinutes) +
                              " minutes.\n          "
                          )
                        ])
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }